
main.coming-soon {
  position: absolute;
  top: 44%;
  transform: translateY(-42%);
  width: 100%;
}

main.coming-soon p {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  text-shadow: 2px 2px 8px #000000;
}

main.coming-soon a,
main.coming-soon a:hover,
main.coming-soon a:visited {
  color: #ffffff;
}

@media all and (width > 480px) {
  
  main.coming-soon p {
    font-size: 4.5vw;
    line-height: 5.5vw;
  }

}

main.coming-soon .coming-soon-trident {
  margin: 50px auto 0;
}

main.coming-soon .coming-soon-trident img {
  width: 6.5vw;
  min-width: 35px;
  max-width: 75px;
}
