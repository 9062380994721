
/*
----------------------------------------------------------
LINKS
----------------------------------------------------------
*/

body.block-nav-links #portal-links {
  display: none;
}

#portal-links {
  position: fixed;
  z-index: 20000;
  top: 4px;
  left: 6px;
}

#portal-links ul {
  list-style-type: none;
}

#portal-links ul li {
  float: left;
  margin-right: 10px;
  font-size: 13px;
  color: #ffffff;
}

#portal-links ul li a,
#portal-links ul li a:hover,
#portal-links ul li a:visited {
  font-size: 11px;
  color: #ffffff;
  text-decoration: underline;
  text-transform: uppercase;
}



/*
----------------------------------------------------------
PORTALS (re: modals)
----------------------------------------------------------
*/

#portals {
  display: none;
  position: fixed;
  z-index: 200000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100%;
  background: var(--primary-background)!important;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (max-device-width:1024px) and (orientation:landscape){
  #portals { display: none!important; }
}

#portals::-webkit-scrollbar {
  display: initial;
}

body.lock::-webkit-scrollbar,
#portals::-webkit-scrollbar {
  display: none;
}

#portals .portal__inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 48px 34px 44px 34px;
  background: var(--primary-background)!important;
  color: #000000;
}

#portals .portal__header a.close {
  display: block;
  text-align: right;
  margin-bottom: 20px;
}

#portals section a.close {
  display: block;
  padding: 15px 0 10px 0;
}

#portals a {
  text-decoration: underline;
}

#portals a:hover {
  text-decoration: none;
}

#portals h1,
#portals h2,
#portals h3,
#portals h4,
#portals h5,
#portals h6,
#portals p {
  margin-bottom: 20px;
  text-align: left;
}

#portals h3 {
  margin-top: 60px;
}

#portals h4 {
  margin-top: 26px;
}

#portals h5 {
  margin-top: 20px;
}

#portals h1,
#portals h2,
#portals h3,
#portals h4,
#portals h5,
#portals h6,
#portals p,
#portals ul li {
  font-family: sans-serif;
}

#portals p {
  font-size: 16px;
  line-height: 22px;
}

#portals ul {
  margin-left: 30px;
}

#portals ul li {
  font-size: 16px;
  line-height: 22px;
}

@media all and (width > 600px) {
  #portals .inner {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media all and (width > 820px) {
  #portals .inner {
    padding-left: 80px;
    padding-right: 80px;
  }
}

