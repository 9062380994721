

/*
----------------------------------------------------------
BOOK - BASE SETTINGS
----------------------------------------------------------
*/

/* reduce flickering on desktop browser resize */
.book .page {
  display: none;
}

/* dual page - desktop */
.book-desktop {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: calc(50% - 11px);
  transform: translate(-50%, -50%);
  width: 94%;
  max-width: 1300px !important;
  margin: 0 auto;
  padding: 0;
}

/* single-page - mobile + tablet */
.book-single {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

/* single-page - mobile */
.book-mobile {
  margin-top: -18px;
}

/* single-page - tablet */
.book-tablet {
  margin-top: -18px;
}

/* vertically centre page copy */
.book .page-copy .page-col-2 .copy-wrapper {
  position: relative;
  top: 45%;
  transform: translateY(-45%);
}

/*
----------------------------------------------------------
BOOK - SINGLE - PAGE
----------------------------------------------------------
*/

.book-single .page-inner {
  margin: 30px 20px;
  width: calc(100% - 40px);
}

.book-single .page.content .page-header {
  position: relative;
  top: 0;
}

.book-single .page.content .page-header .left img,
.book-single .page.content .page-header .right img {
  max-width: 17vw;
}

.book-single .page.content .page-footer {
  position: relative;
  bottom: 0;
}

.book-single .page.content div.left {
  text-align: left;
}

.book-single .page.content div.middle {
  text-align: center;
}

.book-single .page.content div.right {
  text-align: right;
}

.book-single .page.content .page-footer {
  display: table;
  width: 100%;
}

/*
----------------------------------------------------------
BOOK - SINGLE - PAGE - OVERRIDES + RESPONSIVE
----------------------------------------------------------
*/

.book-mobile .page.content .page-footer {
  padding-top: 20px;
}

.book-tablet .page.content .page-footer {
  padding-top: 10px;
}

@media all and (width > 480px) {

  .book-single .page-inner {
    margin: 40px;
    width: calc(100% - 80px);
  }

}

/*
----------------------------------------------------------
PAGE BACKGROUNDS
----------------------------------------------------------
*/

.page.page-bg {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* cover textures */
/* .book-mobile .page.page-cover { background-image: url("../../assets/images/bg-cover-mobile.jpg"); }
.book-tablet .page.page-cover { background-image: url("../../assets/images/bg-cover-tablet.jpg"); } */
.book-mobile .page.page-cover {
  background-image: url("../../assets/images/bg-cover-mobile-ornate.jpg");
  background-size: 100% 100%; 
  
}
.book-tablet .page.page-cover {
  background-image: url("../../assets/images/bg-cover-tablet-ornate.jpg");
  background-size: 100% 100%; 
  
}

/* page textures */
.page.page-left { background-image: url("../../assets/images/ar-default-bg-page-left.jpg"); }
.page.page-right { background-image: url("../../assets/images/ar-default-bg-page-right.jpg"); }

/* illustrations */
.page.illustration-1 { background-image: url("../../assets/images/bg-illustration-1.jpg"); }
.page.illustration-2 { background-image: url("../../assets/images/bg-illustration-2.jpg"); }

/*
----------------------------------------------------------
PAGE: HEADER
----------------------------------------------------------
*/

.page.content .page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/*
----------------------------------------------------------
PAGE: COPY
----------------------------------------------------------
*/

.page.content {
  padding: 5% !important;
}

.page.content .page-copy {
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
}

/*
----------------------------------------------------------
PAGE: FOOTER
----------------------------------------------------------
*/

.book-desktop .page.content .page-footer {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
}

/*
----------------------------------------------------------
PAGE TYPEFACES - COMMON
----------------------------------------------------------
*/

.page h1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.page p {
  text-align: center;
}

.page p.large {
  font-weight: bold;
}

.page .quotation {
  padding: 0;
  margin: 0 auto 20px auto;
  max-width: 86%;
}

/*
----------------------------------------------------------
BOOK - SINGLE - COVER
----------------------------------------------------------
*/

.book-single .page-cover-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.book-single .page-cover-container img.cover-deco {
  width: 100%;
  display: none;
}

.book-single .page-cover img.coverArt {
  position: absolute;
  z-index: 1003;
  top: 18vh;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
}

/*
----------------------------------------------------------
BOOK - TYPOGRAPHY - SINGLE
----------------------------------------------------------
*/

.book-single .page h1 {
  font-size: 6vw;
  line-height: 6vw;
}

.book-single .page p {
  font-size: 2.45vh;
  line-height: 1.2em;
  padding: 0 10px;
}

.book-single .page p.large {
  font-size: 3vh;
  line-height: 1.1em;
}

.book-single .page .quotation p.quote {
  margin-bottom: 5px;
}

.book-single .page .copy p {
  margin-bottom: 20px;
}

.book-single .page .copy p:last-child {
  margin-bottom: 0!important;
}

/*
----------------------------------------------------------
BOOK - TYPOGRAPHY - SINGLE - SMARTPHONES
----------------------------------------------------------
*/

@media all and (width < 480px) and (height > 739px) {
  .book-single .page p { font-size: 2.2vh; }
  .book-single .page p.large { font-size: 2.75vh; }
}

@media all and (width < 480px) and (height >= 780px) {
  .book-single .page h1 { font-size: 6vw; line-height: 6vw; }
  .book-single .page p { font-size: 2.15vh; line-height: 1.15em; padding: 0; }
  .book-single .page p.large { font-size: 3vh; line-height: 1.1em; }
}

@media all and (width < 480px) and (height >= 780px) and (-webkit-device-pixel-ratio: 2) {
  .book-single .page .copy p { font-size: 2vh; line-height: 1.15em; }
  .book-single .page p.guestlist { font-size: 2vh; line-height: 1.15em; }
  .book-single .page p.offsiteLink { font-size: 2vh; line-height: 1.1em; text-transform: uppercase; }
  .book-single .page p.large { font-size: 3vh; line-height: 1.1em; }
}

@media all and (width < 480px) and (height >= 780px) and (-webkit-device-pixel-ratio: 3) {
  .book-single .page .copy p { font-size: 2vh; line-height: 1.15em; }
  .book-single .page p.guestlist { font-size: 2vh; line-height: 1.15em; }
  .book-single .page p.offsiteLink { font-size: 2vh; line-height: 1.1em; text-transform: uppercase; }
  .book-single .page p.large { font-size: 3vh; line-height: 1.1em; }
}

/* mobile hotfix: hide footer decoration on mobile and add padding to .page-copy */
@media all and (width < 480px) {
  /* all mobile pages: hide footer deco */
  .book-mobile .page-footer { display: none !important; }
  /* guestlist: add top padding */
  .book-mobile .page.page-5-mobile .page-col-2 .copy-wrapper { padding-top: 15px !important; }
  /* guestlist: remove side padding */
  .book-mobile .page.page-5-mobile .copy p { padding: 0; margin-bottom: 15px; }
}

/* Android, small */
@media all and (width < 360px) and (height < 700px) {
  .book-single .page h1 { font-size: 5.75vw; line-height: 5.75vw; }
  .book-single .page p { font-size: 2.3vh; line-height: 1.3em; padding: 0 10px; }
  .book-single .page p.large { font-size: 2.8vh; line-height: 1.1em; }
}

/*
----------------------------------------------------------
BOOK - TYPOGRAPHY - SINGLE - TABLET
----------------------------------------------------------
*/

@media all and (width > 480px) {
  .book-single .page h1 { font-size: 4vw; line-height: 4.75vw; }
  .book-single .page p { font-size: 2.75vw; line-height: 1.2em; }
  .book-single .page p.large { font-size: 3.25vw; line-height: 1.1em; }
}

@media all and (width >= 770px) and (width < 1160px) {
  .book-single .page h1 { font-size: 30px; line-height: 30px; }
  .book-single .page p { font-size: 20px; line-height: 25px; }
  .book-single .page p.large { font-size: 26px; line-height: 30px; }
}

@media all and (width >= 600px) and (width < 1160px) and (height < 1024px) {
  .book-single .page h1 { font-size: 3.5vw; line-height: 3.5vw; }
  .book-single .page p { font-size: 1.9vh; line-height: 1.2em; }
  .book-single .page p.large { font-size: 2.3vh; line-height: 1.1em; }
}

.book-tablet .page p {
  padding: 0;
}

.book-tablet .page p.guestlist {
  font-size: 3vw; line-height: 1.4em;
}

/*
----------------------------------------------------------
BOOK - TYPOGRAPHY - SINGLE - DESKTOP
----------------------------------------------------------
*/

.book-desktop .page.content { padding: 4% !important; }
.book-desktop .page h1 { font-size: 24px; line-height: 24px; }
.book-desktop .page p { font-size: 16px; line-height: 20px; margin-bottom: 15px; }
.book-desktop .page .quotation p,
.book-desktop .page p:last-child { margin-bottom: 0; }
.book-desktop .page p.large { font-size: 19px; line-height: 23px; }
.book-desktop .page p.guestlist { font-size: 1.4vh; line-height: 1.3em; }
.book-desktop .page-3-desktop .page-col-2 { padding-top: 2.25vw; }

@media all and (width >= 1220px) {
  .book-desktop .page-3-desktop .page-col-2 { padding-top: 2.5vw; }
  .book-desktop .page p.guestlist { font-size: 1.4vh; }
}

@media all and (width >= 1280px) {
  .book-desktop .page-3-desktop .page-col-2 { padding-top: 3vw; }
  .book-desktop .page p.guestlist { font-size: 1.5vh; }
}

@media all and (width >= 1380px) {
  .book-desktop .page.content { padding: 5% !important; }
  .book-desktop .page h1 { font-size: 26px; line-height: 26px; }
  .book-desktop .page p { font-size: 17px; line-height: 21px; }
  .book-desktop .page p { margin-bottom: 20px; }
  .book-desktop .page .quotation p,
  .book-desktop .page p:last-child { margin-bottom: 0; }
  .book-desktop .page p.large { font-size: 22px; line-height: 26px; }
  .book-desktop .page p.guestlist { font-size: 1.5vh; line-height: 1.4em; }
  .book-desktop .page-3-desktop .page-col-2 { padding-top: 2vw; }
}

@media all and (width >= 1400px) {
  .book-desktop .page-3-desktop .page-col-2 { padding-top: 25px; }
}

/*
----------------------------------------------------------
BINDING: POSITIONING
----------------------------------------------------------
*/

.book-desktop::before {
  background-image: url("../../assets/images/bg-binding-transparent.png");
  position: absolute;
  content: "";
  left: -3%;
  top: -4%;
  width: 109%;
  height: 108%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 850px) and (max-width: 1159px){

  .book-tablet {
    max-width: 864px;
  }

  .book-tablet::before {
  background-image: url("../../assets/images/bg-binding-transparent.png");
    position: absolute;
    content: "";
    left: 10.1%;
    top: -4%;
    width: 80.7%;
    height: 108%;
    overflow: hidden;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  

}

/*
----------------------------------------------------------
BOOK - DESKTOP - RESPONSIVE
----------------------------------------------------------
*/

@media all and (width >= 1160px) {

  /* spine shadow: text pages */

  .page.page-left:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent 94%, #444444 110%);
  }

  .page.page-right:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, transparent 94%, #444444 110%);
  }

  /* spine shadow: illustrations */

  .page.page-left.illustration:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent 94%, #000000 107%);
  }

  .page.page-right.illustration:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, transparent 94%, #000000 107%);
  }

}

/*
----------------------------------------------------------
PAGE DECORATIONS
----------------------------------------------------------
*/

.deco-left,
.deco-right,
.deco-left-top,
.deco-left-mid,
.deco-left-bottom,
.deco-right-top,
.deco-right-mid,
.deco-right-bottom {
  width:63px !important;
}

.deco-left,
.deco-right {
  display: flex;
  flex-direction: column;
  height: 100% ;
}

.deco-left-top,
.deco-left-mid,
.deco-left-bottom,
.deco-right-top,
.deco-right-mid,
.deco-right-bottom {
  background-repeat: no-repeat;
  background-size: contain;
}

.deco-left-top,
.deco-right-top{
  height: 100px !important;
  min-height: 100px  !important;
}

.deco-left-mid,
.deco-right-mid{
  height: 100%;
  background-repeat: repeat;
}

.deco-left-bottom,
.deco-right-bottom{
  height: 37px !important;
  min-height: 37px !important;
}

.deco-left-top { background-image: url("../../assets/images/page-body-deco-left-1.png"); }
.deco-left-mid { background-image: url("../../assets/images/page-body-deco-left-2.png"); }
.deco-left-bottom { background-image: url("../../assets/images/page-body-deco-left-3.png"); }
.deco-right-top { background-image: url("../../assets/images/page-body-deco-right-1.png"); }
.deco-right-mid { background-image: url("../../assets/images/page-body-deco-right-2.png"); }
.deco-right-bottom { background-image: url("../../assets/images/page-body-deco-right-3.png"); }

@media all and (width < 480px) {

  .deco-left,
  .deco-right,
  .deco-left-top,
  .deco-left-mid,
  .deco-left-bottom,
  .deco-right-top,
  .deco-right-mid,
  .deco-right-bottom {
    width:31.5px !important;
  }

  .deco-left-top,
  .deco-right-top{
    height: 50px !important;
    min-height: 50px  !important;
  }

  .deco-left-bottom,
  .deco-right-bottom{
    height: 18.5px !important;
    min-height: 18.5px !important;
  }
}

/*
----------------------------------------------------------
BOOK: ROSE DROP
----------------------------------------------------------
*/

.page-4 .page-inner{
  overflow: visible;
  position: relative;

}

#rose-container{
  overflow: visible;
  position: relative;
  height: auto;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.rose-frame{
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.respect-cta{
  background-image: url("../../assets/images/respect.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  width: 270.5px;
  height: 67px;
  transition: 200ms all ease-in-out ;
  position: relative;
  cursor: pointer;
}

.respect-cta.enabled:hover,
.respect-cta.enabled:active{
  background-image: url("../../assets/images/respect-pressed.png");
}

#rose-container.mobile .rose-frame,
#rose-container.tablet .rose-frame{
  width: 100%;
  height: auto;
}

#rose-container.mobile .rose-frame{
  width: 120%;
  top: 40px;
}

#rose-container.tablet .rose-frame{
  transform: scale(0.9);
  top: -60px;
}

.pay-respect-preamble.desktop{
  flex-grow: 1;
}

.pay-respect-preamble.mobile,
.pay-respect-preamble.tablet {
  position: absolute;
  color: white;
  width: 100%;
  text-align: center;
  text-shadow: 0 1px 3px rgb(0 0 0 / 90%);
}

.pay-respect-preamble.mobile{
  bottom: 60px;
  font-size: 4.5vw !important;
}
.pay-respect-preamble.tablet {
  font-size: 25px !important;
  bottom: 110px;
}

.respect-cta.tablet,
.respect-cta.mobile,
.respect-cta.desktop{
  position: absolute;
  z-index: 999;
}

.respect-cta.mobile{
  width:135.25px;
  height: 34.5px;
  bottom: 15px;
  left: calc(50% - (135.25px/2));
}

.respect-cta.tablet{
  bottom: 25px;
  left: calc(50% - (270.5px/2));
}

.respect-cta.desktop{
  width: 180.33px;
  height: 45.66px;
  position: relative;
  left: calc(50% - (180.333px/2));
  margin-bottom: 10px;
}
