
/*
----------------------------------------------------------
COMMON STYLES
----------------------------------------------------------
*/
.main{
  height: 100%;
}

.main-body {
  padding: 30px 0px;
  margin: 0 auto;
}

.main-container {
  /* width: 95%; */
  margin: 0 auto;
}

.btn-home {
  border: 3px solid #0071dc;
  color: #0071dc !important;
  text-decoration: none !important;
  display: inline-block;
  padding: 10px 20px 9px;
  text-align: center;
  margin: 0 auto;
  border-radius: 50px;
  line-height: 16px;
  font-weight: 600;
  transition: all 200ms ease-in-out;
}

.btn-home:hover {
  background-color: #0071dc;
  color: #fff !important;
  text-decoration: none;
}

.section-header {
  color: #0071dc;
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 27px;
}

.section-sub-head {
  font-size: 19px;
  margin-bottom: 15px;
}

/*
----------------------------------------------------------
HELPERS
----------------------------------------------------------
*/

/* .fade-out{
  animation-name: fadeOut;
  animation-duration: 400ms;
}

.fade-in{
  animation-name: fadeIn;
  animation-duration: 400ms;
}

@keyframes fadeIn {
  from {opacity:0;}
  to {opacity:1}
}

@keyframes fadeOut {
  from {opacity:1;}
  to {opacity:0}
} */

/*
----------------------------------------------------------
TERMS
----------------------------------------------------------
*/

.terms {
  color: #000;
  padding: 20px 25px;
  position: relative;
}

.terms *,
.terms li p,
.terms a {
  color: #000;
}

.terms li {
  margin-left: 40px;
}

.li {
  margin-left: 20px;
}

.terms h3 {
  margin: 20px 0 30px;
}

.terms hr {
  margin: 30px 0;
}

/*
----------------------------------------------------------
RESPONSIVE
----------------------------------------------------------
*/

@media all and (width >=768px) {

  .content-container {
    width: 80%;
  }

  .section-header {
    font-size: 35px;
    line-height: 37px;
  }

  .section-sub-head {
    font-size: 22px;
    margin-bottom: 20px;
  }

}