
/*
----------------------------------------------------------
PORTAL: TERMS AND CONDITIONS
----------------------------------------------------------
*/

#portal__terms {
  display: none;
}

.portal__terms .btnLink {
  font-size: 16px;
  text-decoration: underline;
}

.portal__terms .btnLink:hover {
  text-decoration: none;
}
