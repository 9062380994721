
/*
----------------------------------------------------------
PORTAL: PRIVACY POLICY
----------------------------------------------------------
*/

#portal__privacy {
  display: none;
}

.portal__privacy .btnLink {
  font-size: 16px;
  text-decoration: underline;
}

.portal__privacy .btnLink:hover {
  text-decoration: none;
}
