
/*
----------------------------------------------------------
COMPONENT: BUTTON LINK
----------------------------------------------------------
*/

.btnLink {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: transparent;
  text-decoration: none;
}

.btnLink:hover {
  text-decoration: underline;
}

.btnLink-block {
  display: block;
  width: 100%;
}
