
main.block-landscape {
  position: absolute;
  top: 42%;
  transform: translateY(-42%);
  width: 100%;
}

main.block-landscape p {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  text-shadow: 2px 2px 8px #000000;
}

main.block-landscape a,
main.block-landscape a:hover,
main.block-landscape a:visited {
  color: #ffffff;
}

@media all and (width > 480px) {
  
  main.block-landscape p {
    font-size: 5.5vw;
    line-height: 6.75vw;
  }

}

@media all and (width > 1160px) {
  
  main.block-landscape p {
    font-size: 4.5vw;
    line-height: 5.25vw;
  }

}
